<template>
  <card class="card" v-bind="$attrs">
    <div>
      <form @submit.prevent>
        <div>
          <h4>Datos de la cotización</h4>
          <div class="form-group">
            <label>Moneda</label>
            <select class="custom-select" v-model="formdata.currencyId">
              <option v-for="cur in currencyOptions" :key="cur.value" :value="cur.value">
                {{ cur.label }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="isExchangeType">
          <h4>Datos del canje</h4>
          <div class="form-group">
            <label>Tipo de Canje</label>
            <select class="custom-select" v-model="formdata.hailPaymentTypeId">
              <option v-for="et in this.exchangeTypes" :key="et.id" :value="et.id">
                {{ et.label }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Destino del canje</label>
            <VueSelect :value="defaultCenter" :clearable="false" @input="selectGranCenter" :options="centerOptions">
              <span slot="no-options">No se encontró el centro.</span>
            </VueSelect>
          </div>

          <div class="form-group">
            <label>Lugar de entrega</label>
            <VueSelect :value="defaultStore" :clearable="false" @input="selectGranStored" :options="storedOptions">
              <span slot="no-options">No se encontró lugar de entrega.</span>
            </VueSelect>
          </div>
        </div>

        <h4>Ubicación del riesgo</h4>
        <div>
          <p>
            Provincia:
            <strong>{{ negotiation.risks[0].zone.province.name }}</strong>
          </p>
          <p>
            Zona/Dpto:
            <strong>{{ negotiation.risks[0].zone.description }}</strong>
          </p>
        </div>
        <div class="form-group">
          <label>Localidad</label>
          <VueSelect :clearable="false" @input="selectLocation" :options="locationsOptions">
            <span slot="no-options">No se encontro la localidad.</span>
          </VueSelect>
        </div>

        <fg-input type="number" disabled label="Cod. Postal" required="required" v-model="formdata.zipCode"></fg-input>

        <div class="form-group">
          <label>Punto Cardinal</label>
          <select class="custom-select" v-model="formdata.cardPoint">
            <option v-for="pc in this.cardPoints" :key="pc.id" :value="pc.id">
              {{ pc.label }}
            </option>
          </select>
        </div>

        <fg-input type="number" label="Kilometros" required="required" v-model="formdata.kilometers"></fg-input>

        <div class="form-group" v-show="formdata.street.length > 0">
          <label>Ubicación</label>
          <p>{{ formdata.street }}</p>
        </div>

        <h4>Datos del cliente</h4>

        <div class="row">
          <div class="col-sm-12">
            <fg-input type="text" label="CUIT" required="required" v-model="formdata.client.documentNumber">
              <small class="font-italic text-primary">
                El CUIT debe estar compuesto por 11 números sin guiones ni puntos
              </small>
            </fg-input>
          </div>
        </div>

        <fg-input type="email" label="Email" required="required" v-model="formdata.client.email">
          <small class="font-italic text-primary">Campo requerido</small>
        </fg-input>

        <fg-input type="tel" label="Nro. de telefono" required="required" v-model="formdata.client.phoneNumber">
          <small class="font-italic text-primary">El teléfono debe estar compuesto por 10 números</small>
        </fg-input>

        <h4>Datos del Productor</h4>
        <fg-input
          type="text"
          label="Nro. Productor Sancor"
          required="required"
          v-model="formdata.producerNumber"
          :disabled="!isAdmin"
        ></fg-input>

        <div class="button-container">
          <p-button type="primary" @click.native.prevent="submitForm">Guardar</p-button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>
<script>
import VueSelect from '@/components/VueSelect';

import granCenterjs from './FormProposal/granCenter.js';
import granStoredjs from './FormProposal/granStored.js';
import granStoredPortjs from './FormProposal/granStoredPort.js';

import currenciesjs from './FormProposal/currencies.js';
import { isAdmin, isSuperUser } from '@/plugins/roles.js';
const granCenter = granCenterjs;
const granStored = granStoredjs;
const granStoredPort = granStoredPortjs;
const currencies = currenciesjs;

export default {
  components: {
    VueSelect
  },
  props: {
    province: String,
    zone: String,
    negotiation: Object,
    quotation: Object,
    locations: Array
  },
  data() {
    return {
      hasErrors: false,
      cardPoints: [
        { id: 1, label: 'Norte' },
        { id: 2, label: 'Sur' },
        { id: 3, label: 'Este' },
        { id: 4, label: 'Oeste' },
        { id: 5, label: 'Noreste' },
        { id: 6, label: 'Sureste' },
        { id: 7, label: 'Noroeste' },
        { id: 8, label: 'Suroeste' }
      ],
      exchangeTypes: [
        { id: 4, label: 'Canje Futuro' },
        { id: 5, label: 'Canje Disponible' }
      ],
      centers: [],
      stores: [],
      currencies: [],
      defaultCenter: null,
      defaultStore: null,
      formdata: {
        cityId: 0,
        zipCode: 0,
        cardPoint: '',
        kilometers: 0,
        street: '',
        client: {
          documentNumber: '',
          email: '',
          phoneNumber: ''
        },
        producerNumber: 0,
        currencyId: '',
        hailPaymentTypeId: 2,
        center: '',
        store: ''
      },
      errorMessages: [],
      errorMessage: ''
    };
  },
  created() {
    this.formdata.client.documentNumber = this.clearDash(this.negotiation.client.cuit);
    this.formdata.client.phoneNumber = this.negotiation.client.cell;
    this.formdata.client.email = this.negotiation.client.email;
    this.formdata.producerNumber = this.negotiation.user.sancorId;
    this.centers = granCenter;
    this.currencies = currencies;
    this.setDefaultValues();
  },
  computed: {
    isAdmin() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user());
    },
    locationsOptions() {
      return this.locations
        .map(pl => ({
          label: pl.cityDescription + ' (dpto ' + pl.zoneDescription + ')',
          value: pl.cityId
        }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    },
    centerOptions() {
      return this.centers
        .map(gc => ({
          label: gc.description,
          value: gc.id
        }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    },
    storedOptions() {
      return this.stores
        .map(gs => ({
          label: gs.description,
          value: gs.id
        }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    },
    currencyOptions() {
      return this.currencies
        .filter(
          cur =>
            cur.crop == this.negotiation.risks[0].crop.description &&
            cur.paymentType == this.negotiation.risks[0].hectarePriceCurrency.label
        )
        .map(cur => ({ label: cur.description, value: cur.id }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    },
    isExchangeType() {
      return this.negotiation.paymentMethod.label == 'canje';
    }
  },
  methods: {
    clearDash(str) {
      if (str) {
        return str.replace(/\-/g, '');
      }
      return '';
    },
    selectLocation(location) {
      this.formdata.cityId = location.value;
      this.setZipCode();
    },
    selectGranCenter(center) {
      this.formdata.center = center.value;
      this.formdata.store = '';
      if (center.label == 'Puerto') {
        this.stores = granStoredPort;
      } else {
        this.stores = granStored;
      }
    },
    selectGranStored(store) {
      this.formdata.store = store.value;
    },
    setDefaultValues() {
      if (this.negotiation.risks[0].hectarePriceCurrency.label == 'quintal') {
        const cropDescription = this.negotiation.risks[0].crop.description;
        const crop = cropDescription.split('/')[0];
        const currencyId = this.currencies.find(
          currency =>
            currency.crop.includes(crop) &&
            currency.paymentType === 'quintal' &&
            currency.description.includes('Rosario')
        ).id;
        this.formdata.currencyId = currencyId;
      }
      this.formdata.cardPoint = 1;
      this.formdata.kilometers = 1;
      if (this.negotiation.paymentMethod.label === 'canje') {
        this.formdata.hailPaymentTypeId = 4;
        const center = this.centers.find(center => center.id === 1);
        this.defaultCenter = { label: center.description, value: center.id };
        if (!this.negotiation.user.exchangeStoreId && this.negotiation.user.supervisor) {
          const storeSupervisor = granStoredPort.find(
            store => store.id === this.negotiation.user.supervisor?.exchangeStoreId
          );
          this.defaultStore = storeSupervisor
            ? { label: storeSupervisor.description, value: storeSupervisor.id }
            : { label: '', value: '' };
        } else if (this.negotiation.user.exchangeStoreId) {
          const store = granStoredPort.find(store => store.id === this.negotiation.user.exchangeStoreId);
          this.defaultStore = { label: store.description, value: store.id };
        } else {
          this.defaultStore = { label: 'Puerto Rosario', value: '1' };
        }
      }
    },
    submitForm() {
      if (this.validate()) {
        this.$emit('submit', this.formdata);
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Hay campos incorrectos.',
          type: 'error',
          footer: this.errorMessage
        });
        this.errorMessage = '';
      }
    },
    validate() {
      let isProposalValid = true;

      if (this.formdata.currencyId == '') {
        this.errorMessages.push('Moneda vacia');
        isProposalValid = false;
      }
      if (this.isExchangeType && this.formdata.hailPaymentTypeId != 4 && this.formdata.hailPaymentTypeId != 5) {
        this.errorMessages.push('Tipo de canje vacio');
        isProposalValid = false;
      }
      if (this.isExchangeType && this.formdata.center == '') {
        this.errorMessages.push('Destino de canje vacio');
        isProposalValid = false;
      }
      if (this.isExchangeType && this.formdata.center == '') {
        this.errorMessages.push('Destino de canje vacio');
        isProposalValid = false;
      }
      if (this.isExchangeType && this.formdata.store == '') {
        this.errorMessages.push('Lugar de entrega vacio');
        isProposalValid = false;
      }
      if (this.formdata.cityId == null || this.formdata.cityId == 0) {
        this.errorMessages.push('Localidad vacia');
        isProposalValid = false;
      }
      if (this.formdata.cardPoint == '') {
        this.errorMessages.push('Punto Cardinal vacio');
        isProposalValid = false;
      }
      if (this.formdata.street == '') {
        this.errorMessages.push('Kilometros vacio');
        isProposalValid = false;
      }
      if (this.formdata.client.documentNumber == '') {
        this.errorMessages.push('CUIT vacio');
        isProposalValid = false;
      }
      if (
        this.formdata.client.documentNumber.length !== 11 ||
        this.formdata.client.documentNumber.includes('-') ||
        this.formdata.client.documentNumber.includes('.')
      ) {
        this.errorMessages.push('CUIT debe tener 11 números sin guiones ni puntos');
        isProposalValid = false;
      }
      if (this.$utils.isWrongCuit(this.formdata.client.documentNumber)) {
        this.errorMessages.push('CUIT inválido');
        isProposalValid = false;
      }
      if (!this.$utils.is_email(this.formdata.client.email)) {
        this.errorMessages.push('Formato de email inválido');
        isProposalValid = false;
      }
      if (this.formdata.client.phoneNumber) {
        if (this.formdata.client.phoneNumber.length !== 10) {
          this.errorMessages.push('Teléfono debe tener 10 números');
          isProposalValid = false;
        }
        if (this.formdata.client.phoneNumber.includes('-') || this.formdata.client.phoneNumber.includes('.')) {
          this.errorMessages.push('Teléfono no debe tener guiones o puntos');
          isProposalValid = false;
        }
      } else {
        this.errorMessages.push('Teléfono debe tener 10 números');
        isProposalValid = false;
      }
      if (this.formdata.producerNumber == 0) {
        this.errorMessages.push('Codigo de productor igual a cero');
        isProposalValid = false;
      }
      if (isProposalValid) {
        return true;
      } else {
        this.errorMessage = 'Corregir los siguientes campos: <br>- ' + this.errorMessages.join('.<br>- ');
        this.errorMessages = [];
        return false;
      }
    },
    setZipCode() {
      let targetLocation = this.locations.find(loc => loc.cityId == this.formdata.cityId);
      this.formdata.zipCode = targetLocation.zipCode;
    },
    setStreet() {
      let cardPoint = this.cardPoints.find(cp => cp.id == this.formdata.cardPoint);
      let targetLocation = this.locations.find(loc => loc.city_id == this.formdata.cityId);
      if (cardPoint !== undefined) {
        this.formdata.street =
          'a ' +
          this.formdata.kilometers +
          ' KM al ' +
          cardPoint.label +
          ' de ' +
          (targetLocation ? targetLocation.city_description : '');
      }
    }
  },
  watch: {
    'formdata.cardPoint': {
      handler: function (newVal, oldVal) {
        this.setStreet();
      }
    },
    'formdata.kilometers': {
      handler: function (newVal, oldVal) {
        this.setStreet();
      }
    },
    'formdata.kilometers': {
      handler: function (newVal, oldVal) {
        this.setStreet();
      }
    },
    'formdata.cityId': {
      handler: function (newVal, oldVal) {
        this.setStreet();
      }
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .button-container {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    margin: 5px 0 5px 0;
  }
}
</style>
