<template>
  <card>
    <div>
      <div slot="raw-content" class="table-responsive">
        <entity-table
          :columns="columns"
          :table-data="rows"
          :total="risks.length"
          :has-actions="false"
          :has-search="false"
          :has-pagination="false"
        >
          <template slot-scope="{ row }">
            <td>{{ row.module }}</td>
            <td>${{ costPerHectare(row).toFixed(2) | numberToCurrency }}</td>
            <!--costo en $ por ha (monto a pesificar - iva - percepción iva / hectareas)-->
            <td>
              U$S
              {{ costUsdToday(row).toFixed(2) | numberToCurrency }}
            </td>
            <!--costo en us hoy (costo en $ por ha / cotización del dólar del día)-->
            <td>
              {{ costPerQuintalToday(row).toFixed(3) | numberToCurrency }}
            </td>
            <!--costo en qq de soja hoy-->
            <td>
              {{ costPerQuintalFuture(row).toFixed(3) | numberToCurrency }}
            </td>
            <!--costo en qq de soja a futuro-->
            <td>{{ Number(benefit(row)).toFixed(4) | numberToCurrency }}%</td>
            <!--beneficio % (1 - costo qq de soja a futuro / costo qq de soja hoy)-->
          </template>
        </entity-table>
      </div>
    </div>
  </card>
</template>

<script>
import EntityTable from '@/components/EntityTable';
import { CROPS_MAPPING, CROP_IDS } from '@/utils/general';

const tableColumns = [
  'Nombre cobertura',
  'Costo en $ por HA',
  'Costo en U$S hoy',
  'Costo en QQ de soja hoy (U$S/QQ)',
  'Costo en QQ de soja a futuro (U$S/QQ)',
  'Beneficio %'
];

export default {
  components: {
    EntityTable
  },

  props: {
    dailyParameters: {
      required: true,
      type: Object
    },

    risks: {
      required: true,
      type: Array
    },

    calculatedData: {
      required: true,
      type: Object
    },

    params: {
      required: true,
      type: Object
    }
  },

  filters: {
    numberToCurrency(value) {
      if (!value) return '0.00';
      const intPart = Math.trunc(value);
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      let floatPart = '.00';
      const valueArray = value.toString().split('.');
      if (valueArray.length === 2) {
        floatPart = valueArray[1].toString();
        return intPartFormat + '.' + floatPart;
      }
      return intPartFormat + floatPart;
    }
  },

  data() {
    return {
      cropsMapping: CROPS_MAPPING,
      columns: tableColumns,
      futureCrop: null,
      totalHectares: 0
    };
  },

  created() {
    this.fetchFuture();
    this.totalHectares = this.risks.reduce((carry, next) => {
      return carry + next.hectares;
    }, 0);
  },

  computed: {
    rows() {
      let data = [];
      for (const modulo in this.calculatedData) {
        for (const fod in this.calculatedData[modulo]) {
          data.push({
            module: `${modulo} - ${fod}`,
            ...this.calculatedData[modulo][fod]
          });
        }
      }
      return data;
    }
  },

  methods: {
    async fetchFuture() {
      this.futureCrop = await this.$api.simulator.getFuture(CROP_IDS['soy'], this.getMonth());
    },

    getMonth() {
      if (Number(this.params.applyAllThickDates == 0)) {
        return this.params.thickCropDate.slice(5, 7);
      } else {
        return this.params.soyCropDate.slice(5, 7);
      }
    },

    costPerHectare(row) {
      return (row.pesifyAmount - row.tax - row.taxPerception) / this.totalHectares;
    },

    costUsdToday(row) {
      return this.costPerHectare(row) / this.dailyParameters.dollars;
    },

    costPerQuintalToday(row) {
      return this.dailyParameters.soy > 0 ?this.costUsdToday(row) / (this.dailyParameters.soy / 10):0;
    },

    costPerQuintalFuture(row) {
      return this.futureCrop > 0 ?this.costUsdToday(row) / (this.futureCrop / 10):0;
    },

    benefit(row) {
      return this.costPerQuintalToday(row) > 0 ? (1 - this.costPerQuintalFuture(row) / this.costPerQuintalToday(row)) * 100 : 0;
    }
  }
};
</script>
