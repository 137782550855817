const currencies = [
  // pesos
  { id: 1, description: 'Pesos', crop: 'Maíz', paymentType: 'pesos' },
  { id: 1, description: 'Pesos', crop: 'Sorgo', paymentType: 'pesos' },
  { id: 1, description: 'Pesos', crop: 'Soja', paymentType: 'pesos' },
  { id: 1, description: 'Pesos', crop: 'Girasol', paymentType: 'pesos' },
  { id: 1, description: 'Pesos', crop: 'Trigo', paymentType: 'pesos' },
  { id: 1, description: 'Pesos', crop: 'Cebada', paymentType: 'pesos' },
  {
    id: 1,
    description: 'Pesos',
    crop: 'Centeno',
    paymentType: 'pesos'
  },
  {
    id: 1,
    description: 'Pesos',
    crop: 'Avena',
    paymentType: 'pesos'
  },
  {
    id: 1,
    description: 'Pesos',
    crop: 'Lino',
    paymentType: 'pesos'
  },
  {
    id: 1,
    description: 'Pesos',
    crop: ' Alpiste',
    paymentType: 'pesos'
  },

  // dolares
  { id: 2, description: 'Dólares', crop: 'Sorgo', paymentType: 'dolar' },
  { id: 2, description: 'Dólares', crop: 'Maíz', paymentType: 'dolar' },
  { id: 2, description: 'Dólares', crop: 'Soja', paymentType: 'dolar' },
  { id: 2, description: 'Dólares', crop: 'Girasol', paymentType: 'dolar' },
  { id: 2, description: 'Dólares', crop: 'Trigo', paymentType: 'dolar' },
  { id: 2, description: 'Dólares', crop: 'Cebada', paymentType: 'dolar' },
  {
    id: 2,
    description: 'Dólares',
    crop: 'Centeno',
    paymentType: 'dolar'
  },
  {
    id: 2,
    description: 'Dólares',
    crop: 'Avena',
    paymentType: 'dolar'
  },
  {
    id: 2,
    description: 'Dólares',
    crop: 'Lino',
    paymentType: 'dolar'
  },
  {
    id: 2,
    description: 'Dólares',
    crop: 'Alpiste',
    paymentType: 'dolar'
  },

  // quintales maiz
  {
    id: 26,
    description: 'Qq. Maíz Bs. As.',
    crop: 'Sorgo',
    paymentType: 'quintal'
  },
  {
    id: 32,
    description: 'Qq. Maíz B. Blanca',
    crop: 'Sorgo',
    paymentType: 'quintal'
  },
  {
    id: 48,
    description: 'Qq. Maíz Quequén',
    crop: 'Sorgo',
    paymentType: 'quintal'
  },
  {
    id: 53,
    description: 'Qq. Maíz Rosario',
    crop: 'Sorgo',
    paymentType: 'quintal'
  },
  {
    id: 26,
    description: 'Qq. Sorgo Bs. As.',
    crop: 'Maíz',
    paymentType: 'quintal'
  },
  {
    id: 32,
    description: 'Qq. Sorgo B. Blanca',
    crop: 'Maíz',
    paymentType: 'quintal'
  },
  {
    id: 48,
    description: 'Qq. Sorgo Quequén',
    crop: 'Maíz',
    paymentType: 'quintal'
  },
  {
    id: 53,
    description: 'Qq. Sorgo Rosario',
    crop: 'Maíz',
    paymentType: 'quintal'
  },

  // quintales soja
  {
    id: 34,
    description: 'Qq. Soja Bs. As.',
    crop: 'Soja',
    paymentType: 'quintal'
  },
  {
    id: 50,
    description: 'Qq. Soja B. Blanca',
    crop: 'Soja',
    paymentType: 'quintal'
  },
  {
    id: 52,
    description: 'Qq. Soja Quequén',
    crop: 'Soja',
    paymentType: 'quintal'
  },
  {
    id: 61,
    description: 'Qq. Soja Rosario',
    crop: 'Soja',
    paymentType: 'quintal'
  },

  // quintales girasol
  {
    id: 21,
    description: 'Qq. Girasol Quequén',
    crop: 'Girasol',
    paymentType: 'quintal'
  },
  {
    id: 22,
    description: 'Qq. Girasol Bs. As.',
    crop: 'Girasol',
    paymentType: 'quintal'
  },
  {
    id: 23,
    description: 'Qq. Girasol B. Blanca',
    crop: 'Girasol',
    paymentType: 'quintal'
  },
  {
    id: 49,
    description: 'Qq. Girasol Rosario',
    crop: 'Girasol',
    paymentType: 'quintal'
  },

  // quintales trigo
  {
    id: 29,
    description: 'Qq. Trigo Bs. As.',
    crop: 'Trigo',
    paymentType: 'quintal'
  },
  {
    id: 56,
    description: 'Qq. Trigo Rosario',
    crop: 'Trigo',
    paymentType: 'quintal'
  },
  {
    id: 59,
    description: 'Qq. Trigo Quequén',
    crop: 'Trigo',
    paymentType: 'quintal'
  },
  {
    id: 60,
    description: 'Qq. Trigo B. Blanca',
    crop: 'Trigo',
    paymentType: 'quintal'
  }
];

export default currencies;
