<template>
  <div>
    <div class="form-group">
      <label>Productor</label>
      <VueSelect
        cy-id="producer-select"
        v-if="simpleNegotiation"
        :value="getSimpleNegotiationProducer"
        :clearable="false"
        :options="selectorOptions"
        :disabled="true"
        @input="setProducer"
      >
        <span slot="no-options">No se encontró el productor.</span>
      </VueSelect>
      <VueSelect cy-id="producer-select" v-else :clearable="false" @input="setProducer" :options="selectorOptions">
        <span slot="no-options">No se encontró el productor.</span>
      </VueSelect>
    </div>
  </div>
</template>
<script>
import VueSelect from '@/components/VueSelect';

export default {
  name: 'ProducerForm',
  components: {
    VueSelect
  },
  props: {
    producer: {
      type: Number,
      default: null
    },
    simpleNegotiation: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedProducer: this.producer,
      producers: []
    };
  },
  created() {
    this.fetchProducers();
  },
  computed: {
    formattedSelectedOption() {
      let fso = {
        label: '',
        value: this.selectedProducer
      };
      let prod = this.producers.find(p => p.id === this.selectedProducer);
      if (prod !== undefined) {
        fso.label = this.producerSelectLabel(prod);
      }
      return fso;
    },
    selectorOptions() {
      return this.producers.map(producer => ({
        label: this.producerSelectLabel(producer),
        value: producer
      }));
    },
    getSimpleNegotiationProducer() {
      return {
        label: this.simpleNegotiation.user.name,
        value: this.simpleNegotiation.user.id
      };
    }
  },
  methods: {
    fetchProducers() {
      this.$api.users.getAllUsers().then(response => {
        this.producers = response.data.users;
      });
    },
    setProducer(selectedOption) {
      this.selectedProducer = selectedOption.value;
      this.$emit('onProducerChange', this.selectedProducer);
    },
    producerSelectLabel(producer) {
      let sancorId = producer.sancorId ? producer.sancorId : '';
      return `${producer.name} ( ${sancorId} )`;
    }
  }
};
</script>
<style lang="scss" scoped></style>
