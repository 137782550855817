<template>
  <card>
    <div>
      <div slot="raw-content" class="table-responsive">
        <entity-table
          :table-data="risks"
          :total="risks.length"
          :has-actions="false"
          :has-search="false"
          :has-pagination="false"
        >
          <template #columns>
            <th v-for="column in columns" :key="column.name" :class="column.class">
              {{ column.name }}
            </th>
          </template>
          <template slot-scope="{ row }">
            <td>
              <strong>{{ row.crop }}</strong>
            </td>
            <!--cultivo-->
            <td>{{ row.hectares | numberToCurrency }}</td>
            <!--hectareas-->
            <td>
              {{ row.hectarePrice.toFixed(2) | numberToCurrency }} {{ row.hectarePriceCurrency | currencySymbol }}
            </td>
            <!--suma asegurada por hectárea-->
            <td>{{ row.quotation.rate.module.description }}</td>
            <!--cobertura-->
            <td>{{ row.quotation.rate.franchiseDeductible }}</td>
            <!--franquicia/deducible-->
            <td>{{ row.calculatedQuotation.quotation_value.toFixed(2) | numberToCurrency }}%</td>
            <!--premio % sin iva sin recargo-->
            <td>{{ row.calculatedQuotation.rateWithRecharge.toFixed(2) | numberToCurrency }}%</td>
            <!--premio % sin iva con recargo-->
            <td class="d-print-none">{{ row.calculatedQuotation.boardQuotation.toFixed(2) | numberToCurrency }}</td>
            <!--cotización pizarra $/QQ-->
            <td class="d-print-none">
              {{ row.calculatedQuotation.boardQuotationFuture.toFixed(2) | numberToCurrency }}
            </td>
            <!--cotización futuro $/QQ ó cotización grano/ u$s a futuro rofex-->
            <td>{{ row.calculatedQuotation.prizeWithoutTaxFuture.toFixed(2) | numberToCurrency }}%</td>
            <!--Tasa sin IVA a valor futuro-->
            <td>{{ row.calculatedQuotation.benefitObtained.toFixed(2) | numberToCurrency }}%</td>
            <!--Beneficio neto obtenido-->
            <td>
              {{ row.calculatedQuotation.costPrizeWithoutTax.toFixed(2) | numberToCurrency }}
              {{ row.hectarePriceCurrency | currencySymbol }}
            </td>
            <!--Costo en QQ/U$S a premio sin IVA sin recargo-->
            <td>
              {{ row.calculatedQuotation.costPlus.toFixed(2) | numberToCurrency }}
              {{ row.hectarePriceCurrency | currencySymbol }}
            </td>
            <!--Costo en QQ/U$S a valor futuro-->
            <td class="d-print-none">{{ row.calculatedQuotation.difference.toFixed(2) | numberToCurrency }}</td>
            <!--Diferencia en QQ-->
            <td class="d-none d-print-table-cell">
              {{ row.calculatedQuotation.exchangeCost.toFixed(2) | numberToCurrency }}
            </td>
            <!--Costo por HA en canje/u$s/$-->
            <td class="d-none d-print-table-cell">
              {{ row.calculatedQuotation.futureCost.toFixed(2) | numberToCurrency }}
            </td>
            <!--Costo por ha en qq a valor futuro -->
          </template>
        </entity-table>
      </div>
      <br />
    </div>
    <br />
  </card>
</template>
<script>
import EntityTable from '@/components/EntityTable';
import ExternalValues from './ExternalValues';

const tableColumns = [
  { name: 'Cultivo' },
  { name: 'Hectáreas' },
  { name: 'Suma asegurada por hectárea' },
  { name: 'Cobertura' },
  { name: 'Franquicia/Deducible' },
  { name: 'Tasa recargo', class: 'd-none d-print-table-cell' },
  { name: 'Premio % sin IVA sin recargo' },
  { name: 'Premio % sin IVA con recargo' },
  { name: 'Cotización Pizarra $/QQ', class: 'd-print-none' },
  { name: 'Cotización Futuro $/QQ', class: 'd-print-none' },
  { name: 'Tasa sin IVA a valor futuro' },
  { name: 'Beneficio neto obtenido' },
  { name: 'Costo premio sin IVA sin recargo' },
  { name: 'Costo a valor futuro' },
  { name: 'Diferencia', class: 'd-print-none' },
  { name: 'Costo por HA en canje/u$s/$', class: 'd-none d-print-table-cell' },
  { name: 'Costo por HA en QQ a valor futuro', class: 'd-none d-print-table-cell' }
];

const tableColumnsTwo = ['Cultivo', 'Costo premio sin IVA sin recargo', 'Costo a valor futuro', 'Diferencia'];
const tableColumnsThree = ['Cobertura', 'Monto a pesificar $', 'IVA $', 'Percepción IVA $'];

export default {
  components: {
    EntityTable,
    ExternalValues
  },

  props: {
    futureCrop: {
      type: Object,
      required: true
    },

    risks: {
      required: true,
      type: Array
    }
  },

  filters: {
    numberToCurrency(value) {
      if (!value) return '0.00';
      const intPart = Math.trunc(value);
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      let floatPart = '.00';
      const valueArray = value.toString().split('.');
      if (valueArray.length === 2) {
        floatPart = valueArray[1].toString();
        return intPartFormat + '.' + floatPart;
      }
      return intPartFormat + floatPart;
    }
  },

  data() {
    return {
      columns: tableColumns,
      columnsTwo: tableColumnsTwo,
      columnsThree: tableColumnsThree
    };
  }
};
</script>

<style scoped>
.table-responsive {
  overflow-x: auto;
}

.font {
  font-size: 16px;
}
</style>
