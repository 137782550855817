<template>
  <div class="settings">
    <tabs>
      <tab-pane title="Notificaciones">
        <email-notifications></email-notifications>
      </tab-pane>
      <tab-pane title="Ignorar descuentos">
        <ignore-discounts></ignore-discounts>
      </tab-pane>
      <tab-pane title="Valores de IVA">
        <tax-vat></tax-vat>
      </tab-pane>
      <tab-pane title="Parámetros del simulador">
        <params></params>
      </tab-pane>
    </tabs>
  </div>
</template>

<script>
import IgnoreDiscounts from './Settings/IgnoreDiscounts';
import EmailNotifications from './Settings/EmailNotifications';
import TaxVat from './Settings/TaxVat';
import Params from './Settings/Params';

import Tabs from '@/components/Tabs/Tabs';
import TabPane from '@/components/Tabs/Tab';

export default {
  components: {
    Tabs,
    TabPane,
    IgnoreDiscounts,
    EmailNotifications,
    TaxVat,
    Params
  },

  data() {
    return {
      settings: []
    };
  }
};
</script>

<style lang="scss">
.settings {
  .nav-tabs-navigation {
    margin-bottom: 0px;

    .nav-tabs {
      @media only screen and (max-width: 600px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }

      .nav-item {
        margin-left: 20px;
        color: #666;

        @media only screen and (max-width: 600px) {
          margin: 5px 0;
        }

        .nav-link {
          // border: 5px solid #41b883;
          background-color: rgba(248, 247, 245, 1);
          color: #666;
          -webkit-box-shadow: 0px -1px 4px -3px rgba(204, 197, 185, 0.5);
          box-shadow: 0px -1px 4px -3px rgba(204, 197, 185, 0.5);
          border-radius: 10px 10px 0px 0px;

          &:hover {
            color: #41b883;
          }

          &:focus {
            color: #fff;
          }

          &.active {
            background-color: #fff;
            color: #41b883;
          }

          @media only screen and (max-width: 600px) {
            border-radius: 0px;
          }
        }
      }
    }
  }
}
</style>
