import utils from '@/plugins/utils';

export const validateAlpha = value => {
  const regExp = /^[a-zA-ZÀ-ÿ\d\s.,-°]+$/;
  return regExp.test(value) ? true : false;
};

export const validateCuit = valueCuit => {
  return !utils.isWrongCuit(valueCuit);
};
