import RestResource from '../rest.service';
export default class UsersService extends RestResource {
  map(payload) {
    return {
      email: payload.email,
      name: payload.name,
      role_id: payload.roleId,
      password: payload.password,
      username: payload.username,
      file_number: payload.fileNumber,
      cuit: payload.cuit,
      office: payload.office,
      address: payload.address,
      location: payload.location,
      province: payload.province,
      cell: payload.cell,
      sancor_id: payload.sancorId,
      type: payload.type,
      allow_payment_in_kind: payload.allowPaymentInKind,
      allow_discounts_on_packs: payload.allowDiscountsOnPacks,
      allow_split_coverages: payload.allowSplitCoverages,
      see_pdf_description: payload.seePdfDescription,
      see_video: payload.seeVideo,
      see_coverage_user_module: payload.seeCoverageUserModule,
      supervisor_id: payload.supervisorId,
      preferred_url: payload.preferredUrl,
      send_proposal: payload.sendProposal,
      password_confirmation: payload.password_confirmation,
      current_password: payload.current_password,
      logo: payload.logo,
      max_discount_allow: payload.maxDiscountAllow,
      rate_option_id: payload.paymentMethod,
      payment_method_id: payload.rateOption,
      hectare_price_currency_id: payload.hectarePriceCurrency,
    };
  }

  getAllUsers() {
    return this.get(`${this.prefix}/all-users`);
  }

  getLogo(logo) {
    return this.route + logo;
  }

  getAllUsers() {
    return this.get(`${this.prefix}/all-users`);
  }

  setLogo(userId, logo) {
    return this.post(`${this.route}/${userId}/logo`, logo);
  }

  deleteLogo(userId) {
    return this.delete(`${this.route}/${userId}/logo`);
  }

  addClient(userId, clientId) {
    return this.post(`${this.route}/${userId}/clients/${clientId}`, null);
  }

  removeClient(userId, clientId) {
    return this.delete(`${this.route}/${userId}/clients/${clientId}`);
  }

  sendResetPasswordEmail(user) {
    return this.post(`${this.prefix}/forgot-password`, user);
  }

  resetPassword(newPassword) {
    return this.post(`${this.prefix}/reset-password`, newPassword);
  }

  setSupervisedProducers(id, supervisedProducers) {
    return this.put(`${this.route}/${id}/supervised-producers`, { supervisedProducers });
  }

  getSupervisedProducers(id, params = {}) {
    return this.get(`${this.route}/${id}/supervised_users`, { params });
  }

  getClients(id) {
    return this.get(`${this.route}/${id}/clients`);
  }

  getCoverages(id) {
    return this.get(`${this.route}/${id}/modules`);
  }

  setCoverages(id, coverages) {
    return this.post(`${this.route}/${id}/modules`, coverages);
  }

  addCoverage(id, coverageId) {
    return this.post(`${this.route}/${id}/modules/${coverageId}`, null);
  }

  deleteCoverage(id, coverageId) {
    return this.delete(`${this.route}/${id}/modules/${coverageId}`);
  }

  getClients(userId, params) {
    return this.get(`${this.route}/${userId}/clients`, { params });
  }

  constructor(api) {
    super(api, 'users', 'api');
  }
}
