<template>
  <card v-if="newParams">
    <div class="card-header mb-2 align-items-center">
      <slot name="header">
        <div>
          <h4 class="card-title">{{ title }}</h4>
          <p>Aquí puede cambiar los parámetros del simulador para esta cotización.</p>
          <button v-if="editMode" class="btn btn-light m-2" @click="changeState">Cancelar</button>
          <button v-else class="btn btn-info m-2" @click="changeState">Editar</button>
          <button v-if="editMode" class="btn btn-primary m-2" @click="changeStateSave()">Guardar</button>
        </div>
        <button class="btn btn-link" :class="{ openCard: open }" @click="open = !open">
          <i class="ti-angle-down"></i>
        </button>
      </slot>
    </div>
    <div v-show="open" class="card-body" :class="{ openCardBody: open }">
      <div class="container">
        <div class="row">

          <div class="col grid">
            <div class="grid-row headings">
              <div class="grid-col">
                <p><strong>Cosechas de tipo gruesa</strong></p>
              </div>
              <div class="grid-col">
                <p><strong>Cosechas de tipo fina</strong></p>
              </div>
            </div>
            <div class="grid-row">
              <!-- tasas de recargo gruesa -->
              <div class="grid-col">
                <div v-if="editMode">
                  <label>
                    <input type="checkbox" id="frate" v-model="newParams.applyAllThickRates" name="firstrate" />
                    Aplicar tasas a cultivos individuales
                  </label>
                </div>
                <template v-if="newParams.applyAllThickRates">
                  <div>
                    <div>
                      <p class="h6 mb-2">Tasa Recargo Soja</p>
                      <fg-input
                        class="text-center"
                        v-model="newParams.soyRate"
                        type="number"
                        placeholder="Tasa recargo soja"
                        v-if="editMode"
                      ></fg-input>
                      <p v-else>{{ newParams.soyRate }}%</p>
                    </div>
                    <div>
                      <p class="h6 mb-2">Tasa Recargo Maiz</p>
                      <fg-input
                        class="text-center"
                        v-model="newParams.cornRate"
                        type="number"
                        placeholder="Tasa recargo maíz"
                        v-if="editMode"
                      ></fg-input>
                      <p v-else>{{ newParams.cornRate }}%</p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p class="h6 mb-2">Tasa Recargo Cosechas Gruesas</p>
                    <fg-input
                      class="text-center"
                      v-model="newParams.thickRate"
                      type="number"
                      placeholder="Tasa recargo cosecha gruesa"
                      v-if="editMode"
                    ></fg-input>
                    <p v-else>{{ newParams.thickRate }}%</p>
                  </div>
                </template>
              </div>
              <!-- tasas de recargo fina -->
              <div class="grid-col">
                <div v-if="editMode">
                  <label>
                    <input type="checkbox" id="frate" v-model="newParams.applyAllThinRates" name="secondtrate" />
                    Aplicar tasas a cultivos individuales
                  </label>
                </div>
                <template v-if="newParams.applyAllThinRates">
                  <div>
                    <p class="h6 mb-2">Tasa Recargo Trigo</p>
                    <fg-input
                      class="text-center"
                      v-model="newParams.wheatRate"
                      type="number"
                      placeholder="Tasa recargo trigo"
                      v-if="editMode"
                    ></fg-input>
                    <p v-else>{{ newParams.wheatRate }}%</p>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p class="h6 mb-2">Tasa Recargo Cosechas Finas</p>
                    <fg-input
                      class="text-center"
                      v-model="newParams.thinRate"
                      type="number"
                      placeholder="Tasa recargo cosecha fina"
                      v-if="editMode"
                    ></fg-input>
                    <p v-else>{{ newParams.thinRate }}%</p>
                  </div>
                </template>
              </div>
            </div>
            <div class="grid-row">
              <!-- fechas de vencimiento gruesa -->
              <div class="grid-col">
                <div v-if="editMode">
                  <label>
                    <input type="checkbox" id="fdate" v-model="newParams.applyAllThickDates" name="firstdate" />
                    Aplicar fecha a cultivos individuales
                  </label>
                </div>
                <template v-if="newParams.applyAllThickDates">
                  <div>
                    <div>
                      <p class="h6 mb-2">Fecha vencimiento Soja</p>
                      <fg-input
                        class="text-center"
                        v-model="newParams.soyCropDate"
                        type="date"
                        placeholder="Fecha finalización préstamo para soja"
                        v-if="editMode"
                      ></fg-input>
                      <p v-else>{{ newParams.soyCropDate }}</p>
                    </div>
                    <div>
                      <p class="h6 mb-2">Fecha vencimiento Maíz</p>
                      <fg-input
                        class="text-center"
                        v-model="newParams.cornCropDate"
                        type="date"
                        placeholder="Fecha finalización préstamo para maíz"
                        v-if="editMode"
                      ></fg-input>
                      <p v-else>{{ newParams.cornCropDate }}</p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p class="h6 mb-2">Fecha vencimiento Cosechas Gruesas</p>
                    <fg-input
                      class="text-center"
                      v-model="newParams.thickCropDate"
                      type="date"
                      placeholder="Fecha finalización préstamo para cosecha gruesa"
                      v-if="editMode"
                    ></fg-input>
                    <p v-else>{{ newParams.thickCropDate }}</p>
                  </div>
                </template>
              </div>
              <!-- fechas de vencimiento fina -->
              <div class="grid-col">
                <div v-if="editMode">
                  <label>
                    <input type="checkbox" id="fdate" v-model="newParams.applyAllThinDates" name="firstdate" />
                    Aplicar fecha a cultivos individuales
                  </label>
                </div>
                <template v-if="newParams.applyAllThinDates">
                  <div>
                    <p class="h6 mb-2">Fecha vencimiento Trigo</p>
                    <fg-input
                      class="text-center"
                      v-model="newParams.wheatCropDate"
                      type="date"
                      placeholder="Fecha finalización préstamo para trigo"
                      v-if="editMode"
                    ></fg-input>
                    <p v-else>{{ newParams.wheatCropDate }}</p>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p class="h6 mb-2">Fecha vencimiento cosechas finas</p>
                    <fg-input
                      class="text-center"
                      v-model="newParams.thinCropDate"
                      type="date"
                      placeholder="Fecha finalización préstamo para cosecha fina"
                      v-if="editMode"
                    ></fg-input>
                    <p v-else>{{ newParams.thinCropDate }}</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    dailyParameters: {
      required: true,
      type: Object
    },
    dollars: {
      type: Array,
      required: true
    },
    params: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      title: 'Parámetros de la simulación',
      newParams: null,
      editMode: false,
      open: false,
      monthsMapping: {
        0: 'ENE',
        1: 'FEB',
        2: 'MAR',
        3: 'ABR',
        4: 'MAY',
        5: 'JUN',
        6: 'JUL',
        7: 'AGO',
        8: 'SEP',
        9: 'OCT',
        10: 'NOV',
        11: '(DIS|DIC)'
      },
      cropFutures: []
    };
  },

  computed: {
    getDollar() {
      const selected = this.dollars.find(d => d.id === Number(this.newParams.selectedFutureValue));
      return selected ? selected.value : '';
    }
  },

  mounted() {
    this.newParams = this.params;
  },

  created() {
    this.fetchCropFutures();
    
  },

  methods: {
    fetchCropFutures() {
      this.$api.simulator.getAllCropFutures({ perPage: 100 }).then(res => {
        this.cropFutures = res.data.futures;
      });
    },

    changeState() {
      this.editMode = !this.editMode;
      this.open = !this.open;
    },

    acceptedSoyDate(date) {
      date.setDate(date.getDate() + 1);
      const regExp = new RegExp(
        `^soj\.ros\/${this.monthsMapping[date.getMonth()]}${date.getFullYear().toString().slice(2, 4)}`,
        'i'
      );
      let valid = this.cropFutures.some(future => regExp.test(future.option));
      return valid && (this.isAValidDate(date) || !this.formData.soyDate.hasChanged);
    },

    acceptedCornDate(date) {
      date.setDate(date.getDate() + 1);
      const regExp = new RegExp(
        `^mai\.ros\/${this.monthsMapping[date.getMonth()]}${date.getFullYear().toString().slice(2, 4)}`,
        'i'
      );
      let valid = this.cropFutures.some(future => regExp.test(future.option));
      return valid && (this.isAValidDate(date) || !this.formData.cornDate.hasChanged);
    },

    acceptedThickDate(date) {
      date.setDate(date.getDate() + 1);
      const soyRegExp = new RegExp(
        `^soj\.ros\/${this.monthsMapping[date.getMonth()]}${date.getFullYear().toString().slice(2, 4)}`,
        'i'
      );
      let validSoy = this.cropFutures.some(future => soyRegExp.test(future.option));
      const cornRegExp = new RegExp(
        `^mai\.ros\/${this.monthsMapping[date.getMonth()]}${date.getFullYear().toString().slice(2, 4)}`,
        'i'
      );
      let validCorn = this.cropFutures.some(future => cornRegExp.test(future.option));
      return validSoy && validCorn && (this.isAValidDate(date) || !this.formData.thickDate.hasChanged);
    },

    acceptedThinDate(date) {
      date.setDate(date.getDate() + 1);
      const regExp = new RegExp(
        `^tri\.ros\/${this.monthsMapping[date.getMonth()]}${date.getFullYear().toString().slice(2, 4)}`,
        'i'
      );
      let valid = this.cropFutures.some(future => regExp.test(future.option));
      return valid && (this.isAValidDate(date) || !this.formData.thinDate.hasChanged);
    },

    isAValidDate(date) {
      return new Date(date) >= new Date();
    },

    changeStateSave() {
      this.editMode = false;
        this.$swal({
          title: 'Éxito!',
          text: 'Parámetros modificados éxitosamente.',
          type: 'success',
          footer: ''
        });
        this.$emit('parameters', this.newParams);
    }
  }
};
</script>

<style>
.openCard {
  transform: rotate(180deg);
}

.openCardBody {
  transition: all;
  transition-timing-function: ease-in;
}

.col.grid {
  flex-basis: 30%;
}
.grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
}
.grid-row {
  display: contents;
}
.grid-row.headings .grid-col {
  height: 40px;
  border-top: 0;
}
.grid-col {
  border-top: 1px solid #f4f3ef;
  padding-top: 10px;
}
</style>
