<template>
  <card v-if="futureSoy && futureCorn">
    <h3 class="text-center"><strong>Valores del ROFEX</strong></h3>
    <table class="table">
      <thead></thead>
      <tbody>
        <tr>
          <th scope="row">Cotización del día dólar ROFEX</th>
          <td>${{ dailyParameters.dollars | numberToCurrency }}</td>
        </tr>
        <tr>
          <th scope="row">Cotización del dólar futuro seleccionado</th>
          <td>${{ getFutureDollar | numberToCurrency }}</td>
        </tr>
        <tr>
          <th scope="row">Cotización del día Soja</th>
          <td>{{ (dailyParameters.soy / 10).toFixed(2) | numberToCurrency }} U$S/QQ</td>
        </tr>
        <tr>
          <th scope="row">Cotización del día Maíz</th>
          <td>{{ (dailyParameters.corn / 10).toFixed(2) | numberToCurrency }} U$S/QQ</td>
        </tr>
        <tr>
          <th scope="row">Cotización del día Trigo</th>
          <td>{{ (dailyParameters.wheat / 10).toFixed(2) | numberToCurrency }} U$S/QQ</td>
        </tr>
        <tr>
          <th scope="row">Cotización Soja a Mayo</th>
          <td>{{ (this.futureSoy.futureCrop.value / 10).toFixed(2) | numberToCurrency }} U$S/QQ</td>
        </tr>
        <tr>
          <th scope="row">Cotización Maíz a Abril</th>
          <td>{{ (this.futureCorn.futureCrop.value / 10).toFixed(2) | numberToCurrency }} U$S/QQ</td>
        </tr>
        <tr>
          <th scope="row">Cotización Soja a futuro seleccionada</th>
          <td>{{ (this.futureCrop.soy.futureCrop.value / 10).toFixed(2) | numberToCurrency }} U$S/QQ</td>
        </tr>
        <tr>
          <th scope="row">Cotización Maíz a futuro seleccionada</th>
          <td>{{ (this.futureCrop.corn.futureCrop.value / 10).toFixed(2) | numberToCurrency }} U$S/QQ</td>
        </tr>
        <tr>
          <th scope="row">Cotización Trigo a futuro seleccionada</th>
          <td>{{ (this.futureCrop.wheat.futureCrop.value / 10).toFixed(2) | numberToCurrency }} U$S/QQ</td>
        </tr>
      </tbody>
    </table>
  </card>
</template>
<script>
import { CROPS, CROP_IDS } from '@/utils/general';

export default {
  props: {
    dailyParameters: {
      required: true,
      type: Object
    },

    futureCrop: {
      required: true,
      type: Object
    },

    risks: {
      required: true,
      type: Array
    },

    params: {
      required: true,
      type: Object
    },

    dollars: {
      required: true,
      type: Array
    },

    futureCropQuotation: {
      required: true,
      type: Object
    }
  },

  filters: {
    numberToCurrency(value) {
      if (!value) return '0.00';
      const intPart = Math.trunc(value);
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      let floatPart = '.00';
      const valueArray = value.toString().split('.');
      if (valueArray.length === 2) {
        floatPart = valueArray[1].toString();
        return intPartFormat + '.' + floatPart;
      }
      return intPartFormat + floatPart;
    }
  },

  data() {
    return {
      futureSoy: null,
      futureCorn: null,
      crops: CROPS,
      cropIds: CROP_IDS
    };
  },

  computed: {
    getFutureDollar() {
      return (
        this.dollars.find(d => d.id == this.params.selectedFutureValue)?.value ?? this.dailyParameters.futureDollar
      );
    },

    getCurrentCropIds() {
      return [...new Set(this.risks.map(risk => risk.cropId))];
    }
  },

  mounted() {
    this.fetchFutures();
  },

  methods: {
    fetchFutures() {
      this.$api.simulator.getFuture(1, '05').then(response => (this.futureSoy = response));
      this.$api.simulator.getFuture(2, '04').then(response => (this.futureCorn = response));
    }
  }
};
</script>

<style scoped>
.table-responsive {
  overflow-x: auto;
}
</style>
