export default {
  // rate options
  WITHVAT: 1,
  WITHOUTVAT: 2,
  BEFORETAXES: 3,
  // hectare price currencies
  QUINTAL: 1,
  DOLLAR: 2,
  PESO: 3,
  // payment methods
  EXCHANGE: 1,
  FINANCED: 2,
  KIND: 3,
  EXCHANGE_LABEL: 'Canje',
  FINANCED_LABEL: 'Financiado',
  KIND_LABEL: 'Pago en Especie',
  //negotiations state
  ACCEPTED: 'aceptada',
  REJECTED: 'rechazada',
  NEW: 'iniciada',
  UPDATED: 'recotizada',
  SOLICITUDE: 'solicitud',
  // harvest video type
  THICK_HARVEST: { name: 'Gruesa', key: 'thick' },
  THIN_HARVEST: { name: 'Fina', key: 'thin' },
  // currencies types
  QUINTAL: 1,
  DOLLAR: 2,
  PESO: 3,
  // negotiation types
  SIMPLE: 'simple',
  GROUPED: 'grouped',
  INITIATED: 'iniciada',
  // confirmation negotiation
  CONFIRMED: 1,
  NOT_CONFIRMED: 0
};
