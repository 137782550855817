<template>
  <div class="riesgo">
    <div class="riesgo-head">
      <p class="riesgo-head-title">{{ updateHeadTitle }}</p>
      <span class="riesgo-head-button">
        <p-button type="warning" class="mr-2" size="xs" @click.native="toggleForm">
          <i class="fa fa-chevron-down" :class="[this.isOpen ? 'rotate' : 'desRotate']" aria-hidden="true" />
        </p-button>
        <p-button
          cy-id="remove-item"
          type="danger"
          size="xs"
          @click.native="removeItem"
          :disabled="disabled && isSimpleNegotiation"
        >
          <i class="fa fa-times" aria-hidden="true" />
        </p-button>
      </span>
    </div>
    <div class="form" :class="[this.isOpen ? 'openForm' : 'closeForm']">
      <div class="riesgo-form p-3">
        <risk-form
          :currency="currency"
          :disabled="disabled"
          ref="riskForm"
          @cambianDatos="updateRisk"
          :risk="risk"
          :is-simple-negotiation="isSimpleNegotiation"
        />
      </div>
      <div class="double-crop form" v-if="this.isDoubleCropAvailable && !this.isSimpleNegotiation">
        <div class="double-crop-option form px-5 mb-4">
          <double-crop-select
            :key="'crop'"
            :selected="isDoubleCrop"
            :disabled="disabled"
            @change="toggleDoubleCropForm"
          />
        </div>
        <div class="p-3 form" v-if="this.isDoubleCrop && !this.isSimpleNegotiation">
          <risk-form
            :key="'doubleCrop'"
            :hectares="riskForm.numberOfHectares"
            :zone="riskForm.zone"
            :isDoubleCrop="true"
            ref="riskFormDoubleCrop"
            :currency="currency"
            :risk="risk"
            :disabled="disabled"
            @cambianDatos="setDoubleCropRisk"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoubleCropSelect from '@/components/Risk/DoubleCropSelect.vue';
import RiskForm from '../Forms/RiskForm.vue';

export default {
  name: 'RiskItem',
  components: {
    DoubleCropSelect,
    RiskForm
  },
  props: {
    isSimpleNegotiation: {
      type: Boolean,
      default: false
    },
    startOpen: {
      type: Boolean,
      default: false
    },
    risk: {
      type: Object,
      default: null
    },
    currency: {
      type: [Number, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: this.startOpen,
      headTitle: 'Campo #' + this.$vnode.key,
      riskForm: {
        id: this.$vnode.key,
        description: '',
        zone: null,
        crop: null,
        numberOfHectares: 0,
        hectarePriceCurrencyId: this.currency,
        hectarePrice: 0,
        doubleCropId: null
      },
      isDoubleCrop: Boolean(this.risk && this.risk.doubleCropId)
    };
  },
  computed: {
    isDoubleCropAvailable() {
      return Boolean((this.riskForm.zone !== null && this.hasDoubleCrop) || this.risk?.doubleCrop);
    },
    hasDoubleCrop() {
      return this.riskForm.crop !== null && this.riskForm.crop?.allowDoubleCrop === 1;
    },
    updateHeadTitle() {
      const haUnit = this.$options.filters.unidadValorHA(this.riskForm.hectarePriceCurrencyId);
      const titleDate = [
        this.riskForm.description,
        this.riskForm.zone?.description,
        this.riskForm.zone?.province.name,
        this.riskForm.crop?.description,
        `${this.riskForm.numberOfHectares} HA`,
        `${this.riskForm.hectarePrice} ${haUnit}/HA `,
        this.isDoubleCrop ? 'Doble cultivo' : null
      ]
        .filter(t => t)
        .join(' | ');

      return this.riskForm.zone !== null ? titleDate : this.headTitle;
    }
  },
  methods: {
    toggleDoubleCropForm(value) {
      this.isDoubleCrop = value;
      if (this.risk) {
        this.riskForm.doubleCrop = this.risk.doubleCrop;
        this.riskForm.doubleCropId = this.risk.doubleCropId;
      } else if (!value) {
        this.riskForm.doubleCrop = null;
      }
    },
    toggleForm() {
      this.isOpen = !this.isOpen;
    },
    validate() {
      if (!this.isDoubleCrop) {
        if (!this.$refs.riskForm.validate()) this.isOpen = true;
        return this.$refs.riskForm.validate();
      } else if (this.isSimpleNegotiation) {
        if (!this.$refs.riskForm.validate()) this.isOpen = true;
        return this.$refs.riskForm.validate();
      } else {
        const riskFormValidate = this.$refs.riskForm.validate();
        const riskFormDoubleCropValidate = this.$refs.riskFormDoubleCrop.validate();
        if (!riskFormValidate || !riskFormDoubleCropValidate) this.isOpen = true;
        return riskFormValidate && riskFormDoubleCropValidate;
      }
    },
    saveChanges() {
      // primero validar los campos
      if (this.validate()) {
        this.isOpen = false;
        this.updateHeadTitle();
        this.$emit('save', this.riskForm);
      }
    },
    updateRisk(riskData) {
      this.riskForm = { ...this.riskForm, ...riskData, id: this.riskForm.id };
    },
    removeItem() {
      this.$emit('remove', this.riskForm);
    },
    setDoubleCropRisk(payload) {
      this.riskForm.doubleCropId = payload.crop?.id;
      this.riskForm.doubleCrop = {
        description: payload.description,
        hectarePrice: payload.hectarePrice
      };
    }
  },
  watch: {
    riskForm: {
      handler(nuevo, viejo) {
        this.$emit('addRisk', nuevo);
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.riesgo {
  border: 1px solid #ddd;
  margin-bottom: 20px;

  .riesgo-head-button {
    position: absolute;
    right: 20px;
    top: 14px;
  }

  .closeForm {
    max-height: 0px;
    min-height: 0px;
    overflow: hidden;
    opacity: 0.3;
  }

  .rotate {
    transform: rotate(180deg);
    transition: all 500ms;
  }

  .desRotate {
    transform: rotate(360deg);
    transition: all 500ms;
  }
  .openForm {
    max-height: 1300px;
    opacity: 1;
  }
  .form {
    transition: all 800ms;
  }

  .riesgo-head {
    position: relative;
    padding: 15px;
    background: #eee;
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column-reverse;
      .riesgo-head-button {
        position: initial;
        align-self: flex-end;
        margin-bottom: 10px;
      }
    }

    .riesgo-head-title {
      margin: 0;
      min-height: 22px;
    }
  }

  .riesgo-form {
    border-top: 1px solid #ddd;
  }
}
</style>
