<template>
  <div class="row">
    <div class="col-xl-8" v-if="this.isDataLoaded">
      <form-proposal
        :locations="possibleLocations"
        :negotiation="this.negotiation"
        @submit="submitProposal"
        :quotation="this.quotation"
        title="Nueva propuesta"
      ></form-proposal>
    </div>
  </div>
</template>

<script>
import FormProposal from '@/components/Forms/FormProposal';
export default {
  components: {
    FormProposal
  },
  data() {
    return {
      quotation: {},
      negotiation: {},
      possibleLocations: [],
      isNegotiationDataLoaded: false,
      isLocationDataLoaded: false
    };
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchData();
      next();
    });

    next();
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData();
    next();
  },
  computed: {
    isDataLoaded() {
      return this.isNegotiationDataLoaded && this.isLocationDataLoaded;
    }
  },
  methods: {
    fetchData() {
      // api call
      let idQuotation = this.$route.params.id;
      this.$api.quotations
        .getOne(idQuotation)
        .then(response => {
          this.quotation = response.data;
          if (this.quotation.negotiationId) {
            this.fetchNegotiation(this.quotation.negotiationId);
            this.fetchPossibleLocations(this.quotation.rate.zone.id);
          }
        })
        .catch(error => {});
    },
    fetchNegotiation(negotiationId) {
      this.$api.negotiations
        .getOne(negotiationId)
        .then(response => {
          this.negotiation = response.data;
          this.isNegotiationDataLoaded = true;
        })
        .catch(error => {});
    },
    fetchPossibleLocations(zoneId) {
      this.$api.locations.getPossibleLocations(zoneId).then(response => {
        this.possibleLocations = response.data.sancor_location;
        this.isLocationDataLoaded = true;
      });
    },
    submitProposal(proposal) {
      // api call
      this.$api.quotations
        .submitProposal(proposal, this.quotation.id)
        .then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'La propuesta ' + response.managementNumber + ' fue cargada en CEIBO de forma exitosa.',
            type: 'success'
          });
          this.$router.push({
            name: 'ver cotizacion',
            params: { id: this.negotiation.id }
          });
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: 'Ocurrio un error mientras se intentaba pasar a propuesta en sistema CEIBO.',
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="scss"></style>
