<template>
  <div class="row">
    <div class="w-100 px-3">
      <form class="d-flex flex-row justify-content-between edit-form" @submit.prevent>
        <div class="form-group" :class="getColumnClass" v-if="canChangeUsers">
          <label>Seleccione productor</label>
          <VueSelect
            :clearable="false"
            :disabled="disableEdit"
            :options="usersOptions"
            :value="defaultUser"
            @input="setUser"
            ref="VueSelect"
          >
            <span slot="no-options">No se encontró el productor.</span>
          </VueSelect>
        </div>
        <div class="form-group" :class="getColumnClass">
          <label>Seleccione cobertura</label>
          <VueSelect
            v-if="edit"
            :disabled="disableEdit"
            :clearable="false"
            :options="coveragesOptions"
            :value="edittedCoverage"
            @input="setLink"
            ref="VueSelect"
          >
            <span slot="no-options">No se encontró la cobertura.</span>
          </VueSelect>
          <VueSelect v-else :clearable="false" :options="coveragesOptions" @input="setLink" ref="VueSelect">
            <span slot="no-options">No se encontró la cobertura.</span>
          </VueSelect>
        </div>

        <fg-input
          :class="getColumnClass"
          type="text"
          label="Link del video"
          placeholder="https://example.com"
          v-model="formData.link"
        ></fg-input>

        <div class="form-group d-flex align-items-end button-container" :class="getColumnClass">
          <p-button class="mx-1" type="primary" @click.native.prevent="submitForm">Guardar</p-button>
          <p-button class="mx-1" type="danger" @click.native.prevent="clearForm">Cancelar</p-button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>

<script>
import VueSelect from '@/components/VueSelect';
import constants from '@/plugins/constants.js';
import { isAdmin, isSuperUser } from '@/plugins/roles.js';
export default {
  name: 'NewCoverageUser',
  components: {
    VueSelect
  },
  props: {
    coverageUsers: {
      type: Array,
      required: false
    },
    edit: {
      type: Object,
      default: null
    },
    disableEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {
        coverageId: 0,
        link: '',
        userId: this.$auth.user().id
      },
      coverages: [],
      errorMessage: '',
      errorMessages: [],
      defaultUser: {
        value: this.$auth.user().id,
        label: this.$auth.user().name
      },
      users: []
    };
  },
  computed: {
    coveragesOptions() {
      return this.coverages.map(coverage => ({
        label: `${coverage.name}`,
        value: coverage.id
      }));
    },
    edittedCoverage() {
      return {
        label: `${this.edit.name}`,
        value: this.edit.id
      };
    },
    canChangeUsers() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user());
    },
    usersOptions() {
      return this.users.map(user => ({
        label: user.name,
        value: user.id
      }));
    },
    getColumnClass() {
      return this.canChangeUsers
        ? 'col-xl-3 col-lg-12 col-12 custom-columns'
        : 'col-xl-4 col-lg-12 col-12 custom-columns';
    }
  },
  created() {
    this.fetchCoverages();
    if (this.canChangeUsers) {
      this.fetchUsers();
    }
  },
  methods: {
    submitForm() {
      if (this.validate()) {
        this.$emit('submit', this.formData);
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Hay campos incorrectos.',
          type: 'error',
          footer: this.errorMessage
        });
        this.errorMessage = '';
      }
    },
    validate() {
      let isDataValid = true;
      if (!this.formData.link.startsWith('https://') && !this.formData.link.startsWith('http://')) {
        this.errorMessages.push('El link debe iniciar con https:// o http://');
        isDataValid = false;
      }
      if (this.formData.coverageId === 0) {
        this.errorMessages.push('Seleccione una cobertura');
        isDataValid = false;
      }
      if (isDataValid) {
        return true;
      } else {
        this.errorMessage = 'Corregir los siguientes campos: <br>- ' + this.errorMessages.join('.<br>- ');
        this.errorMessages = [];
        return false;
      }
    },
    clearForm() {
      this.$refs.VueSelect.clearSelection();
      this.formData.link = '';
      this.formData.coverageId = 0;
      this.$emit('clearEdit');
    },
    fetchCoverages(params = { 'filter[search]': '', perPage: 50, currentPage: 1 }) {
      this.$api.coverages.getAll(params).then(response => {
        this.coverages = response.data.coverages;
      });
    },
    setLink(selectedOption) {
      if (selectedOption) {
        this.formData.coverageId = selectedOption.value;
        let coverage = this.coverageUsers.find(this.getSelectedCoverage);
        if (coverage) {
          if (coverage.link) {
            this.formData.link = coverage.link;
          }
        } else {
          this.formData.link = '';
        }
      }
    },
    getSelectedCoverage(coverageUser) {
      return coverageUser.id === this.formData.coverageId;
    },
    fetchUsers() {
      this.$api.users.getAllUsers().then(response => {
        this.users = response.data.users;
      });
    },
    setUser(selectedOption) {
      if (selectedOption.value) {
        this.clearForm();
        this.formData.userId = selectedOption.value;
        this.$emit('changeUser', selectedOption.value);
      }
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 1199px) {
  .row .edit-form {
    flex-direction: column !important;
  }

  .row .edit-form .custom-columns {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 425px) {
  .button-container {
    flex-direction: column;
    align-items: center !important;
  }

  .button-container .btn {
    width: 100%;
    margin: 5px;
  }
}
</style>
