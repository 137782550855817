<template>
  <div>
    <div class="alert alert-warning alert-with-icon" v-if="isCanjeAbierto">
      <span data-notify="icon" class="ti-shift-right" />
      <span data-notify="message">
        Según la unidad de valor elegida usted esta cotizando un
        <b>CANJE ABIERTO</b>
        .
      </span>
    </div>
    <div class="row" cy-id="settings-form">
      <div class="col-md-4">
        <div class="form-group">
          <label>Forma de pago</label>
          <select class="custom-select" v-model="formData.paymentMethod" @change="setRateOptions()">
            <option v-for="pm in availablePaymentMethods" :key="pm.code" :value="pm.code">
              {{ pm.label }}
            </option>
          </select>
        </div>

        <div class="form-group" v-show="formData.paymentMethod !== 0">
          <label>Opción de tasa</label>
          <select class="custom-select" v-model="formData.rateOption">
            <option v-for="ro in availableRateOptions" :key="ro.code" :value="ro.code">
              {{ ro.label }}
            </option>
          </select>
        </div>

        <fg-input
          type="number"
          label="F.Corrección"
          placeholder="Factor de corrección"
          v-if="pagoEnEspecieSeleccionado"
          v-model="formData.correctionFactor"
        />
      </div>

      <div class="col-md-8">
        <div class="form-group">
          <label>Observaciones</label>
          <textarea class="form-control" v-model="formData.observations" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isAdmin, isSuperUser } from '@/plugins/roles.js';
import constants from '@/plugins/constants.js';

export default {
  name: 'SettingsForm',
  props: {
    currency: {
      type: [Number, String],
      default: 0
    },
    configuraciones: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      paymentMethods: [
        {
          code: constants.EXCHANGE,
          label: 'Canje',
          rateOptions: [
            { code: constants.WITHOUTVAT, label: 'Premio SIN IVA' },
            { code: constants.WITHVAT, label: 'Premio CON IVA' }
          ]
        },
        {
          code: constants.FINANCED,
          label: 'Financiado',
          rateOptions: [
            { code: constants.WITHOUTVAT, label: 'Premio SIN IVA' },
            { code: constants.WITHVAT, label: 'Premio CON IVA' }
          ]
        },
        {
          code: constants.KIND,
          label: 'Pago en Especie',
          rateOptions: [{ code: constants.BEFORETAXES, label: 'Premio antes de impuestos' }]
        }
      ],
      formData: {
        paymentMethod: this.configuraciones.paymentMethod,
        rateOption: this.configuraciones.rateOption,
        correctionFactor: 0,
        observations: this.configuraciones ? this.configuraciones.observations : ''
      }
    };
  },
  methods: {
    setRateOptions() {
      this.formData.rateOption = '';
    },
    setConfig(){
      this.formData.paymentMethod = this.configuraciones.paymentMethod
      this.formData.rateOption =  this.configuraciones.rateOption
    },
  },
  computed: {
    availablePaymentMethods() {
      let apm = [];
      let pms = [constants.EXCHANGE_LABEL, constants.FINANCED_LABEL];
      if (this.pagoEnEspecieDisponible) {
        pms.push(constants.KIND_LABEL);
      }
      apm = this.paymentMethods.filter(pm => pms.includes(pm.label));
      return apm;
    },
    availableRateOptions() {
      if (this.formData.paymentMethod !== 0) {
        return this.paymentMethods.find(pm => pm.code === this.formData.paymentMethod)?.rateOptions ?? [];
      }
      return [];
    },
    isCanjeAbierto() {
      return (
        (this.currency === constants.PESO || this.currency === constants.DOLLAR) &&
        this.formData.paymentMethod === constants.EXCHANGE
      );
    },
    pagoEnEspecieDisponible() {
        if (isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user())) {
          return true;
        }
        if (this.$auth.user().allow_payment_in_kind !== undefined && this.$auth.user().allow_payment_in_kind === 1) {
          return true;
        } else {
          return false;
        }
      
    },

    pagoEnEspecieSeleccionado() {
      return this.pagoEnEspecieDisponible && this.formData.paymentMethod === constants.KIND;
    }
  },
  watch: {
    formData: {
      handler(nuevo, viejo) {
        this.$emit('cambioConfiguraciones', nuevo);
      },
      deep: true
    },
    configuraciones: {
      handler(nuevo, viejo) {
        this.setConfig()
      },
      deep: true
    }
  }
};
</script>
