<template>
  <div v-if="negotiation">
    <h4>Estas son las cotizaciones generadas</h4>
    <div class="row">
      <div class="col-md">
        <p v-if="negotiation.client">
          Cliente:
          <strong>{{ negotiation.client.name ?? '' }} {{ negotiation.client.lastname }}</strong>
        </p>
        <p>
          Productor:
          <strong>{{ negotiation.user.name }}</strong>
        </p>
      </div>
      <div class="col-md">
        <p>
          Forma de pago:
          <strong>{{ negotiation.paymentMethodId | formaPago }}</strong>
        </p>
        <p>
          Opción de tasa:
          <strong>{{ negotiation.rateOptionId | opcionTasa }}</strong>
        </p>
      </div>
    </div>
    <h4>Detalles de los riesgos</h4>
    <div class="row preview-risk-item" v-for="(risk, index) in this.negotiation.risks" :key="`risks-${index}`">
      <div class="col-md">
        <p>
          Nombre:
          <strong>{{ risk.description }}</strong>
        </p>
        <p>
          Dpto.:
          <strong>{{ risk.zone.description }}</strong>
        </p>
        <p>
          Cultivo:
          <strong>{{ risk.crop.description }}</strong>
        </p>
      </div>
      <div class="col-md">
        <p>
          Cantidad de hectáreas:
          <strong>{{ risk.numberOfHectares }} HA</strong>
        </p>
        <p>
          Valor de {{ risk.hectarePriceCurrency.id | unidadValorHA }} por HA:
          <strong>{{ risk.hectarePrice }} {{ risk.hectarePriceCurrency.id | unidadValorHA }}/HA</strong>
        </p>
      </div>
      <div class="table-responsive">
        <tabla-cotizaciones
          v-if="negotiation.rateOptionId !== 0"
          :rateOption="negotiation.rateOptionId"
          :unidadValor="risk.hectarePriceCurrency.id"
          :quotations="risk.quotations"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TablaCotizaciones from '@/components/TablaCotizaciones';

export default {
  name: 'VistaPrevia',
  components: {
    TablaCotizaciones
  },
  props: {
    negotiation: Object
  }
};
</script>

<style>
.preview-risk-item {
  padding-top: 20px;
  border: 1px solid #ccc5b9;
  margin-bottom: 20px;
}
.preview-risk-item .tabla-cotizaciones {
  margin-bottom: 0px;
}
</style>
