<template>
  <div class="row">
    <div class="col-12">
      <card :title="negotiationsTable.title" :subTitle="negotiationsTable.subtitle">
        <template slot="header">
          <div class="pull-left">
            <h4 class="card-title">{{ negotiationsTable.title }}</h4>
            <p class="card-category">{{ negotiationsTable.subtitle }}</p>
          </div>
          <div class="card-header-button-container">
            <button
              v-if="this.$auth.check('ROLE_ADMIN') || this.$auth.check('ROLE_SUDO')"
              class="btn btn-default"
              @click="acceptedQuotationsReport"
            >
              Reporte Cot. Aceptadas
            </button>
            <router-link to="/admin/negociaciones/grupo" class="btn btn-info" cy-id="multiple-quotations">
              Cotizar varios riesgos
            </router-link>
            <router-link to="/admin/negociaciones/nuevo" class="btn btn-primary" cy-id="simple-quotation">
              Agregar nuevo
            </router-link>
          </div>
        </template>

        <div></div>

        <div slot="raw-content" class="table-responsive">
          <negotiation-table
            :columns="negotiationsTable.columns"
            :tableData="negotiationsTable.data"
            type="hover"
            :routeName="negotiationsTable.routeName"
            :total="negotiationsTable.total"
            @refetch="getNegotiations"
            has-simulation
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import NegotiationTable from '@/components/Negotiation/NegotiationTable';
import { isAdmin, isSuperUser } from '@/plugins/roles.js';
import { downloadBlob } from '@/plugins/files.js';

export default {
  components: {
    NegotiationTable
  },
  data() {
    return {
      negotiationsTable: {
        title: 'Cotizaciones',
        subtitle:
          'Estas son las cotizaciones registradas. Para agregar una nueva, presioná el botón que está a la derecha.',
        columns: ['#', 'Fecha', 'Productor', 'Cliente', 'Zona y Cultivo - Riesgo', 'Pago - Opción tasa', 'Simulación'],
        total: 0,
        data: [],
        routeName: 'ver cotizacion'
      }
    };
  },

  created() {
    this.getNegotiations();
  },
  computed: {
    isAdmin() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user());
    }
  },
  methods: {
    getNegotiations(params = { 'filter[search]': '' }) {
      params['filter[is_confirmed]'] = true;

      if (!this.isAdmin) params['filter[user]'] = this.$auth.user().id;

      this.$api.negotiations.getAll(params).then(res => {
        this.negotiationsTable.data = res.data.negotiations;
        this.negotiationsTable.total = res.meta.total;
      });
    },

    acceptedQuotationsReport() {
      this.$api.quotations.acceptedAdvancedReport().then(response => {
        downloadBlob(new Blob([response]), 'reporte_aceptadas.pdf');
      });
    }
  }
};
</script>
<style></style>
